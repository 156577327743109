import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { IAshCharacter } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import './employee.scss';
import './ash-character-dynamic.scss';
import { Col, Row } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faCircleInfo,
  faDiagramProject,
  faPeopleGroup,
  faReceipt,
  faSquare
} from '@fortawesome/free-solid-svg-icons';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface IAshCharacterNodes {
  nodes: IAshCharacter[];
}

interface IAshCharacterEntry {
  currentUnit: IAshCharacterNodes;
}

interface IProps {
  data: IAshCharacterEntry;
}

const AshCharacterDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];
  const [currentTab, setCurrentTab] = useState('Info');

  const fullImage = character.fullImage
    ? getImage(character.fullImage.localFile.childImageSharp)
    : null;

  const profileImage = character.cardImage
    ? getImage(character.cardImage.localFile.childImageSharp)
    : null;

  return (
    <DashboardLayout
      className={'generic-page character-page character-ash'}
      game="ash"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/characters">Characters</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <>
        <div className="character-top">
          <div className="left-info">
            <h1>
              <span className="small"></span>
              <strong className={`${character.element}`}>
                {character.name}
              </strong>
              <span className="sub">Build and Guide</span>
            </h1>
          </div>
          <div className="right-image">
            <div
              className={`character-background ${character.element} ${character.slug}`}
            ></div>
            <GatsbyImage image={profileImage} alt="Character" />
          </div>
        </div>
        <Row className="intro-section">
          <Col xs={12} xl={9}>
            <div className={`content-header ${character.element}`}>
              <FontAwesomeIcon icon={faSquare} width="18" /> Introduction
            </div>
            <div className="character-intro">
              <div className="combined">
                <h2>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  is a{' '}
                  <strong className={`rarity-${character.rarity}`}>
                    {character.rarity}✦
                  </strong>{' '}
                  rarity Echomancer of the{' '}
                  <>
                    {character.class === 'Ranger' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/class_ranger.webp"
                          alt={character.class}
                        />
                      </>
                    )}
                    {character.class === 'Skirmisher' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/class_skirmisher.webp"
                          alt={character.class}
                        />
                      </>
                    )}
                    {character.class === 'Striker' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/class_striker.webp"
                          alt={character.class}
                        />
                      </>
                    )}
                    {character.class === 'Support' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/class_support.webp"
                          alt={character.class}
                        />
                      </>
                    )}
                    {character.class === 'Tactician' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/class_tactician.webp"
                          alt={character.class}
                        />
                      </>
                    )}
                    {character.class === 'Vanguard' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/class_vanguard.webp"
                          alt={character.class}
                        />
                      </>
                    )}
                  </>{' '}
                  <strong className={`${character.class}`}>
                    {character.class}
                  </strong>{' '}
                  class who wields the{' '}
                  <>
                    {character.element === 'Corrosion' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/ele_corrosion.webp"
                          alt={character.element}
                        />
                      </>
                    )}
                    {character.element === 'Fire' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/ele_fire.webp"
                          alt={character.element}
                        />
                      </>
                    )}
                    {character.element === 'Ice' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/ele_ice.webp"
                          alt={character.element}
                        />
                      </>
                    )}
                    {character.element === 'Lightning' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/ele_lightning.webp"
                          alt={character.element}
                        />
                      </>
                    )}
                    {character.element === 'Physical' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/ele_physical.webp"
                          alt={character.element}
                        />
                      </>
                    )}
                    {character.element === 'Water' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/ele_water.webp"
                          alt={character.element}
                        />
                      </>
                    )}
                    {character.element === 'Wind' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/ele_wind.webp"
                          alt={character.element}
                        />
                      </>
                    )}
                  </>{' '}
                  <strong className={`${character.element}`}>
                    {character.element}
                  </strong>{' '}
                  Element. The character was originally released on{' '}
                  <strong>{character.releaseDateCn}</strong> in China{' '}
                  {character.releaseDateGlobal ? (
                    <>
                      and they will release on{' '}
                      <strong>{character.releaseDateGlobal}</strong> in Global.
                    </>
                  ) : (
                    <>but their Global release date isn't known yet.</>
                  )}
                </h2>
                {character.introduction ? (
                  <>
                    <p>
                      {character.profileInfo
                        ? character.profileInfo.Intro
                        : '-'}
                    </p>
                  </>
                ) : (
                  ''
                )}
              </div>
              <p className="hide-on-mobile">
                To learn more about{' '}
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                check the sections below. <strong>Use the tabs</strong> to
                quickly switch to the kind of information you're looking for.
              </p>
            </div>
          </Col>
          <Col xs={12} xl={3}>
            <div className="video-promo">
              <div className={`content-header ${character.element}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Video guide
              </div>
              <div className="video-guide">
                <p>This character has no video guide yet.</p>
              </div>
            </div>
          </Col>
        </Row>
        <div className="last-updated">
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Last updated
          </div>
          <div className="info-box with-margin">
            <p>
              <strong className={`${character.element}`}>
                {character.name}
              </strong>{' '}
              profile was last updated on <strong>{character.updatedAt}</strong>
              .
            </p>
          </div>
        </div>
        <div
          className="fuse-ad-placeholder bigger"
          data-fuse="22844297232"
        ></div>
        <p className="show-on-mobile">
          To learn more about{' '}
          <strong className={`${character.element}`}>{character.name}</strong>{' '}
          check the sections below. <strong>Use the tabs</strong> to quickly
          switch to the kind of information you're looking for.
        </p>
        <div className="tabs">
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Info' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Info')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
            </div>
            <p>Skills</p>
          </div>
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Review' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Review')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faBook} width="18" />
            </div>
            <p>Review</p>
          </div>
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Build' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Build')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faDiagramProject} width="18" />
            </div>
            <p>Stats & Build</p>
          </div>
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Teams' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Teams')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faPeopleGroup} width="18" />
            </div>
            <p>Synergy & Teams</p>
          </div>
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Performance' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Performance')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faReceipt} width="18" />
            </div>
            <p>Profile & Tea Time</p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Info' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Profile
            </p>
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Tags
          </div>
          <div className="specialities-list">
            <div className="employee-role">{character.tags.Tag_1}</div>
            {character.tags.Tag_2 && (
              <div className="employee-role">{character.tags.Tag_2}</div>
            )}
            {character.tags.Tag_3 && (
              <div className="employee-role">{character.tags.Tag_3}</div>
            )}
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Skills
          </div>
          {character.cnOnly === true && (
            <>
              <div className="info-box with-margin">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  is only available in the CN version of the game currently. We
                  translated{' '}
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  skills ourselves, as such, there's a chance of localization
                  errors. We will add the official translations as soon as they
                  will be released. Please contact us directly to report any
                  errors.
                </p>
              </div>
            </>
          )}
          <div className="info-box">
            <p>
              <strong className={`${character.element}`}>
                {character.name}
              </strong>{' '}
              skills aren't available yet. They will be added soon!
            </p>
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Gallery
          </div>
          <Row xs={1} xl={2} className={`char-gallery`}>
            <Col>
              <div className="box">
                <h5 className={` ${character.element}`}>S0</h5>
                {fullImage ? (
                  <GatsbyImage
                    image={fullImage}
                    alt=""
                    className="full-image"
                  />
                ) : (
                  <p>S0 image for this character isn't available yet.</p>
                )}
              </div>
            </Col>
            <Col>
              <div className="box">
                <h5 className={`${character.element}`}>S4</h5>
                <p>S4 image for this character isn't available yet.</p>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className={`tab-inside ${currentTab === 'Review' ? 'active' : ''}`}
        >
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faBook} width="18" />
              Review
            </p>
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Intro
          </div>
          {character.introduction ? (
            <div className="section-analysis">
              <div className={`review raw`}>
                {character.introduction ? (
                  <>{renderRichText(character.introduction, options)}</>
                ) : (
                  <p>Intro is pending.</p>
                )}
              </div>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>introduction isn't available yet.</strong> It will be
                added soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
          </div>
          {character.pros ? (
            <div className="section-analysis">
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.pros, options)}
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.cons, options)}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>pros & cons aren't available yet.</strong> They will be
                added when the character is released.
              </p>
            </div>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Review
          </div>
          {character.review ? (
            <div className="section-analysis">
              <div className={`review raw`}>
                {character.review ? (
                  <>{renderRichText(character.review, options)}</>
                ) : (
                  <p>Review is pending.</p>
                )}
              </div>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>review isn't available yet.</strong> It will be added
                soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Ratings
          </div>
          <div className="info-box">
            <p>
              <strong className={`${character.element}`}>
                {character.name}
              </strong>{' '}
              <strong>ratings aren't available yet.</strong> They will be added
              when the character is released.
            </p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Build' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faDiagramProject} width="18" />
              Stats and Build
            </p>
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Ascension
          </div>
          <div className="info-box">
            <p>
              <strong className={`${character.element}`}>
                {character.name}
              </strong>{' '}
              <strong>Ascension stats aren't available yet</strong>. They will
              be added soon!
            </p>
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Build
          </div>
          <div className="info-box">
            <p>
              <strong className={`${character.element}`}>
                {character.name}
              </strong>{' '}
              <strong>build information aren't available yet</strong>. They will
              be added soon!
            </p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Teams' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faPeopleGroup} width="18" />
              Synergy & Teams
            </p>
          </div>
          <div className={`content-header  ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Synergy & Teams
          </div>
          <div className="info-box">
            <p>
              <strong className={`${character.element}`}>
                {character.name}
              </strong>{' '}
              <strong>Synergy & Teams information aren't available yet</strong>.
              They will be added soon!
            </p>
          </div>
        </div>
        <div
          className={`tab-inside ${
            currentTab === 'Performance' ? 'active' : ''
          }`}
        >
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faReceipt} width="18" />
              Profile & Tea Time
            </p>
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Profile
          </div>
          {character.profileInfo ? (
            <>
              <Row xs={1} xxl={3} className="va-section">
                <Col>
                  <div className="info-list-row">
                    <div className="category">Name</div>
                    <div className="details">
                      {character.profileInfo.Name
                        ? character.profileInfo.Name
                        : '-'}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">World</div>
                    <div className="details">
                      {character.profileInfo.World
                        ? character.profileInfo.World
                        : '-'}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">Affiliation</div>
                    <div className="details">
                      {character.profileInfo.Affiliation
                        ? character.profileInfo.Affiliation
                        : '-'}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">Gender</div>
                    <div className="details">
                      {character.profileInfo.Gender
                        ? character.profileInfo.Gender
                        : '-'}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">Height</div>
                    <div className="details">
                      {character.profileInfo.Height
                        ? character.profileInfo.Height
                        : '-'}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">Birthday</div>
                    <div className="details">
                      {character.profileInfo.Birthday
                        ? character.profileInfo.Birthday
                        : '-'}
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>profile information aren't available yet</strong>.
                  They will be added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> CV
          </div>
          <Row xs={1} xxl={2} className="va-section">
            <Col>
              <div className="info-list-row">
                <div className="category">CN</div>
                <div className="details">
                  {character.profileInfo.CV_CN
                    ? character.profileInfo.CV_CN
                    : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">JPN</div>
                <div className="details">
                  {character.profileInfo.CV_JPN
                    ? character.profileInfo.CV_JPN
                    : '-'}
                </div>
              </div>
            </Col>
          </Row>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Tea Time
          </div>
          <div className="info-box">
            <p>
              <strong className={`${character.element}`}>
                {character.name}
              </strong>{' '}
              <strong>Tea Time information aren't available yet</strong>. They
              will be added soon!
            </p>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </>
    </DashboardLayout>
  );
};

export default AshCharacterDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' | Ash Echoes | Prydwen Institute'}
      description={
        character.name +
        ' from Ash Echoes. Find the best builds, teams, synergy, and other information here.'
      }
      image={character.fullImage}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulAshCharacter(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...AshCharacterFieldsFragment
      }
    }
  }
`;
